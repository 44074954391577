import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    bofa1: file(relativePath: { eq: "Bank_of_America/bofa-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa2: file(relativePath: { eq: "Bank_of_America/bofa-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa4: file(relativePath: { eq: "Bank_of_America/bofa-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa5: file(relativePath: { eq: "Bank_of_America/bofa-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa6: file(relativePath: { eq: "Bank_of_America/bofa-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa7: file(relativePath: { eq: "Bank_of_America/bofa-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa8: file(relativePath: { eq: "Bank_of_America/bofa-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa9: file(relativePath: { eq: "Bank_of_America/bofa-09.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bofa10: file(relativePath: { eq: "Bank_of_America/bofa-10.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.bofa6.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.bofa1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.bofa2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.bofa4.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The drop downs in the hero module act as a filter - dynamic content would be generated for the user based on their selection. The idea was that the content would then be relavent to the user and therefore be more engaging and thoughtful.</p>
      <ImageContainer classes="small" fluid={props.data.bofa5.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.bofa8.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">As selections are made in the hero modules - products begin to populate. The idea was to have a set of products grouped together here to become a solution set for a small or large business.</p>
      <ImageContainer classes="small" fluid={props.data.bofa7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.bofa10.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.bofa9.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">Please contact me if you"d like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      